.control {
  @apply ring-transparent focus-within:ring-blue-500 ring-1 focus-within:border-blue-500 relative block w-full border
  border-gray-300 bg-white placeholder-gray-400 focus:z-10 sm:text-sm py-2 px-3;

  &.rounded {
    @apply rounded-md;
  }

  &.error {
    @apply focus-within:ring-red-500 border-red-500;
  }

  &:invalid,
  &--error {
    @apply border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500;
  }

  &--icon {
    @apply pl-10;
  }

  &:disabled {
    @apply bg-gray-50;
    cursor: not-allowed;
  }
}

.input {
  @apply appearance-none outline-none border-none ring-0 focus:outline-none focus:ring-0 h-auto p-0 leading-4 block w-full;
}
