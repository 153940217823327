.button {
  @apply inline-flex items-center border border-transparent font-medium rounded shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
         disabled:opacity-50 disabled:cursor-not-allowed;

  &--primary {
    @apply text-white bg-blue-600 hover:bg-blue-500 disabled:bg-blue-600;
  }

  &--secondary {
    @apply text-blue-700 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-200;

    &:disabled {
      .button-icon {
        @apply text-blue-700;
      }
    }
  }

  &--destructive {
    @apply text-red-700 bg-red-100 hover:bg-red-200 disabled:bg-red-200 focus:ring-red-500;

    &:disabled {
      .button-icon {
        @apply text-red-700;
      }
    }
  }

  &--white {
    @apply border-gray-300 text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-50;

    .button-icon {
      @apply text-gray-400;
    }

    &:disabled {
      .button-icon {
        @apply text-gray-400;
      }
    }
  }

  &--full-w {
    @apply justify-center w-full;
  }

  &--xs {
    @apply px-2.5 py-1.5 text-xs;
  }

  &--sm {
    @apply px-3 py-2 text-sm leading-4;

    .button-icon {
      &--leading {
        @apply -ml-0.5 mr-2 h-4 w-4;
      }
    }
  }

  &--md {
    @apply px-4 py-2 text-sm;

    .button-icon {
      &--leading {
        @apply -ml-1 mr-2 h-5 w-5;
      }
    }
  }

  &--lg {
    @apply px-4 py-2 text-base;

    .button-icon {
      &--leading {
        @apply -ml-1 mr-3 h-5 w-5;
      }
    }
  }

  &--xl {
    @apply px-6 py-3 text-base;

    .button-icon {
      &--leading {
        @apply -ml-1 mr-3 h-5 w-5;
      }
    }
  }
}
