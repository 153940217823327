.input {
  @apply focus:ring-blue-500 focus:border-blue-500 relative block w-full sm:text-sm border-gray-300 focus:z-10;

  &.rounded {
    @apply rounded-md;
  }

  &--error {
    @apply border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 z-10;
  }

  &--trailing-addon {
    @apply pr-12;
  }

  &--icon {
    @apply pl-10;
  }

  &:disabled {
    @apply bg-gray-50;
    cursor: not-allowed;
  }
}
